// importing utilties
import GlobeSVG from "../../utils/globe_svg.jsx";
import Typewriter from "../../utils/typewritter";
import FadeInSection from "../../utils/fade_in_section";

// importing assets
import gfgLogo from "../../assets/gfglogowithwhitebackground.png";
// import gfgLogo from "../../assets/gfg-student-chapter-new-logo.png";
import pccoeLogo from "../../assets/image_3.png";
import consoleLogo from "../../assets/console.svg";

import Header from "../../Components/Header/Header.js";
import Slider from "../../Components/Slider/Slider.js";
import Footer from "../../Components/Footer/Footer.js";

const HomePage = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header className="z-1" />
      <div className="xs:mt-24 lg:mt-48 mb-24 w-10/12 mx-auto">
        {/* container containing all the aboslute divs */}
        <div className="absolute absolute-container">
          {/* background gradient circles */}
          <div className="absolute -left-32 -top-48 xs:w-0 sm:w-[691.78px] h-[660.39px] origin-top-left rounded-full blur-[650px] -z-10" />
          {/* end of background gradient circles */}
        </div>
        
        <FadeInSection>
          <div className="section-1 flex items-center justify-between mx-auto sm:flex-col lg:flex-row xs:flex-col gap gap-x-6 mt-24">
            {/* Heading */}
            <div className="heading-section">
              <div className="xs:text-[10vw] lg:text-[4vw] font-bold hover:text-gfg-green transition ease-in-out duration-300 leading-none">
                PCCOE's
              </div>
              <div className="xs:text-[10vw] lg:text-[4vw] font-bold leading-none">
                GeeksforGeeks
              </div>
              <div className="xs:text-[10vw] lg:text-[4vw] font-bold leading-none">
                Student Chapter
              </div>
            </div>

            {/* Logos */}
            <div className="logo-section h-1/4 flex flex-row gap-x-6 xs:my-24 lg:my-0">
              <img
                className="xs:w-[30vw] lg:w-[15vw] object-contain"
                src={gfgLogo}
                alt="GFG logo"
              />

              <div className="text-6xl font-bold self-center">x </div>

              <img
                className="xs:w-[30vw] lg:w-[15vw] object-contain"
                src={pccoeLogo}
                alt="Pccoe logo"
              />
            </div>
          </div>
        </FadeInSection>

        <FadeInSection>
          {/* subheading */}
          <div className="flex flex-row my-4 xs:justify-center lg:justify-start">
            <div className="sm:text-left lg:w-2/5">
              <p className="xs:text-[2rem] lg:text-[2vw] text-gray-600 xs:text-center lg:text-left xs:leading-10 xs:pb-8">
                Sculpting Tomorrow's Coders!
              </p>
              <p className="xs:text-[1rem] lg:text-[1.2vw] text-gray-600 xs:text-center lg:text-left">
                We are a vibrant community of budding programmers and technology
                enthusiasts from Pimpri Chinchwad College of Engineering
                (PCCOE).
              </p>
            </div>
          </div>
        </FadeInSection>

          <Slider/>

        <FadeInSection>
          {/* Another Centered Tagline */}
          <div className="flex flex-col justify-center items-center lg:mt-10 xs:mt-24 md:mt-72 mb-32 min-h-[500px] bg-[#3d3d3d] rounded-xl px-8">
            <img
              className="absolute -z-20 -translate-y-28 invisible w-9/12"
              src={consoleLogo}
              alt=""
            />
            <p className="font-extrabold text-center lg:w-4/6 sm:5/6 xs:text-[8vw] s:text-[5vw] sm:text-4xl text-white">
              What's Next in Tech?{" "}
              <div>
                <Typewriter
                  texts={[
                    "Empowering Minds through Computer Science!",
                    "Empowering Minds through Innovation and Collaboration!",
                    "Empowering Minds, Igniting Innovation!",
                    "A Journey with a CS Student Chapter!",
                  ]}
                  delay={50}
                  backspeed={20}
                  infinite={true}
                />
              </div>
            </p>
          </div>
        </FadeInSection>

        <div className="mt-48 xs:mt-24">
          {/* Row containng text and image */}
          <FadeInSection>
            <div className="flex justify-between xs:flex-col lg:flex-row">
              {/* Column containing heading and text below */}
              <div className="text-section flex flex-col sm:w-3/4 lg:w-5/12 sm:mx-auto lg:mx-0">
                <p className="xs:text-4xl s:text-5xl font-bold my-8">
                  What is{" "}
                  <span className="hover:text-gfg-green transition ease-in-out duration-300 xs:text-4xl s:text-5xl">
                    GeeksforGeeks Student Chapter?
                  </span>
                </p>

                <div className="image-section object-fit xs:w-4/5 lg:w-2/5 mx-auto xs:my-12 lg:mt-0 xs:block lg:hidden">
                  <GlobeSVG />
                </div>

                <p className="xs:text-md sm:text-xl text-gray-400">
                  Our community seeks to cultivate a culture of constant
                  learning, creativity, and cooperation among students who are
                  passionate about coding, algorithms, and technology. We equip
                  our members to flourish in the ever-changing digital scene
                  through seminars, hackathons, coding challenges, and
                  knowledge-sharing events.
                </p>

                <br />
                <p className="xs:text-md sm:text-xl text-gray-400">
                  Through interactive workshops, insightful webinars,
                  hackathons, and coding competitions, we empower student to not
                  just write code, but to craft elegant solutions that make a
                  tangible impact. By connecting classroom knowledge with
                  real-world applications, we prepare everyone to excel in the
                  ever-evolving technological landscape.
                </p>
              </div>

              <div className="image-section object-fit xs:w-4/5 lg:w-2/5 mx-auto xs:mt-24 lg:mt-0 xs:hidden lg:block">
                <GlobeSVG />
              </div>
            </div>
          </FadeInSection>

          {/* Gradient Background  */}
          {/* <div className="absolute -right-32 w-[691.78px] h-[660.39px] bg-gfg-green origin-top-left rounded-full blur-[450px] -z-10 xs:top-[1829px] lg:inset-y-full" /> */}
          {/* End of Gradient background */}

          {/* Back to top button */}
          <div className="flex flex-row justify-center sm:mt-48 xs:mt-24">
            <button
              className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-full shadow xs:text-sm sm:text-lg"
              onClick={scrollToTop}
            >
              Back to Top
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
