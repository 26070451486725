import React from "react";
import { useEffect } from "react";
import Timeline from "../../../Components/timeline";

const Dashboard = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  }, []);

  return (
    <>
      <div className="main-container w-full h-full">
        <div className="dashboard-details-container flex xl:flex-row s:flex-col gap-x-2">
          <div className="hackathon-timeline-container rounded-xl border border-slate-200 xl:w-[70%] s:w-full h-content xl:p-12 s:p-4 flex flex-col items-start">
            <h1 className="font-bold text-4xl mb-8">Hackathon Timeline</h1>
            <h2 className="text-md text-black mb-8">
              The below timeline would be strictly followed for Online Round-1
              of Hack Matrix 3.0. You can keep a track of time remaining until
              the conclusion of this round on the dashboard screen.
            </h2>
            <div className="xl:ml-2 s:ml-0">
              <Timeline></Timeline>
            </div>
          </div>

          <div className="hackathon-guidelines-container rounded-xl border border-slate-200 xl:w-[30%] s:w-full xl:mt-0 s:mt-8 h-fit p-8">
            <div className="font-bold text-4xl mb-8">Guidelines</div>
            <h2 className="text-md text-black mb-8">
              Participants are expected to read and comply to the Rulebook
              below. Any actions against the Rulebook would be unacceptable and
              could result in disqualification from the Hackathon.
            </h2>

            <a href="https://drive.google.com/file/d/1sYItNYsAy7PdjNIgbbC6B7ZoXognI20J/view?usp=drivesdk" target="_blank" className="underline cursor-pointer select-none"> Hackathon RuleBook </a>
          </div>
        </div>

        {/* Back to top button */}
        {/* <div className="flex flex-row justify-center mt-12">
          <button
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-full shadow"
            onClick={scrollToTop}>
            Back to Top
          </button>
        </div> */}
      </div>
    </>
  );
};

export default Dashboard;
