import React from "react";
import HMLogo from "../../assets/hm.png";
import logo from "../../assets/gfglogowithwhitebackground.png";
import pccoeLogo from "../../assets/image_3.png";
import aimsalogo from "../../assets/AIMSA.png";
function HackMatrixFooter() {
  return (
    <div className="p-10 mt-20 w-full bg-gray-50 flex justify-around border items-center xl:flex-row s:flex-col">
      <div className="s:my-8">
        <h2 className="font-bold s:text-center" style={{ fontSize: "35px" }}>
          Follow Us
        </h2>
        <div className="text-black">
          <ul className="flex">
            <li>
              <a
                target="_blank"
                className="text-4xl mx-3"
                href="https://www.linkedin.com/company/gfgpccoe"
              >
                <i className="bx bxl-linkedin"></i>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                className="text-4xl mx-3"
                href="https://www.instagram.com/gfg_pccoe/"
              >
                <i className="bx bxl-instagram"></i>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                className="text-4xl mx-3"
                href="https://twitter.com/gfg_pccoe"
              >
                <i className="bx bxl-twitter"></i>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                className="text-4xl mx-3"
                href="https://www.youtube.com/@PCCOEGeeksforGeeks"
              >
                <i className="bx bxl-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex flex-row justify-centers gap-x-10 s:mt-2">
        <a href="/"><img src={logo} alt="PCCOEGFG" width={150} height={150} /></a>
        <a href="https://www.pccoepune.com/"><img src={pccoeLogo} alt="HackMatrixLogo" width={170} height={150} /></a>
        {/* <img src={HMLogo} alt="HackMatrixLogo" width={100} /> */}
        <a href="https://www.pccoeaimsa.in/"><img src={aimsalogo} alt="HackMatrixLogo" width={158} height={150} /></a>
      </div>
      <div className="xl:mr-10 s:mr-0 s:flex s:justify-center xl:w-fit s:w-full s:mt-12">
        <div>
          <h2 className="font-bold s:text-center" style={{ fontSize: "35px" }}>
            Contact Us
          </h2>
          <div className="text-xl">
            <p style={{ fontSize: "25px" }}>
              <a href="tel:+917058025957">+91 7058025957</a>,{" "}
              <a href="tel:+919307503539">+91 9307503539</a>
              <br />
              <br />
              For General Queries :<br />
              <a href="mailto:geeksforgeeks@pccoepune.org">
                geeksforgeeks@pccoepune.org
              </a>
              <br />
              <br />
              For Technical Support:
              <br />
              <a href="tel:+919970102190">+91 9322193906</a>,{" "}
              <a href="tel:+919322477753">+91 9322477753</a>
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HackMatrixFooter;
