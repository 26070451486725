import React from "react";
import { PSAccordion } from "../Accordian/MyAccordion";
import { useState } from "react";
import calculateTimeDifference from "../../utils/timeDifference";

const ProblemStatementsPage = () => {
  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeDifference(new Date(), new Date("2025-03-01T11:00:00")) / 1000
  );
  const tracks = [
    {
      name: "Ed-Tech",
      problemStatements: [
        "Create an interactive T&P system with personalized dashboards for admins and students. Admins post keyword-tagged opportunities, while students upload resumes, receiving notifications for relevant job offers based on their profiles.",
        "Develop a student platform to track progress, lessons, and quizzes. Students engage with interactive content and quizzes, while global matchmaking pairs them with peers for concept-based quiz competitions, offering gamified rewards and credits for motivation",
        "Create a YouTube-like educational platform for uploading, sharing, and viewing videos, with manual admin verification for content relevance. The platform includes a journey analyzer that visually tracks user progress, offering insights into their learning path.",
        "Design an integrated ERP system with role-based smart dashboards to streamline resource management, finances, and scheduling for educational institutions. It enhances communication, enables seamless resource sharing, and automates quiz result distribution, improving student engagement and feedback efficiency.",

             ],
    },
    {
      name: "Health Care",
      problemStatements: [
        "Design an integrated healthcare management system with role-based dashboards for patients, doctors, and administrators to streamline workflows and improve communication. It includes a smart queuing system for appointments, inventory management, and automated medicine reminders to enhance patient care and optimize hospital operations.",
        "Design a secure mental health support platform with personalized user profiles to track history and progress, ensuring tailored assistance. Implement safeguards against fake profiles and enable secure communication with certified professionals to foster a trustworthy and supportive environment.",
        "Design a supportive platform for expectant mothers, offering health and wellness information, a calendar for tracking appointments and progress, prenatal and postnatal care guidelines, and food/diet tracking to promote a healthy pregnancy journey.",
        "Develop a mobile app for athletes to track health and fitness goals, featuring automatic calorie calculation, GPS-enabled running tracker, and a gamified goal-setting system to motivate users, track progress, and promote a healthy lifestyle.",
            ],
    },
    {
      name: "Miscellaneous",
      problemStatements: [

        "Design a financial management app that centralizes accounts, transactions, and investments for a comprehensive overview. Include a transaction analyzer, categorized spending, and data visualization to help users track and optimize their finances effectively.",
        "Create a user-friendly student marketplace for buying and selling educational resources, with secure product type restrictions and user registrations. Include enhanced search functionality to help students find specific textbooks, stationery, and materials quickly and affordably.",
        "Design an interactive competition platform for hosting hackathons, DSA, CP, and ML contests. Allow organizers to post events, enable easy participant registration, and incorporate a gamified leveling system, rewards, leaderboards, and social sharing to enhance engagement and foster community.",
        "Design an all-in-one event management platform with real-time communication tools (chat, discussion boards, file sharing), an integrated event timeline, calendar with notifications, role-based access, budget tracking, and analytics to streamline collaboration and ensure event success.",

            ],
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const downloadPS = () => {
    let url = "https://docs.google.com/document/d/1Do12tnpxElVpFW9kw-f4w2m2eypMJYJYT0wK0l_VfjU/edit?usp=sharing";
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="rounded-xl border xl:p-10 s:p-2">
        {timeRemaining > 0 ? (
          <div>
            <h1 className="text-4xl font-bold mb-6 xl:text-left s:text-center">
              Hackathon Tracks
            </h1>
            <h1 className="text-red-600 xl:text-[1.4vw] s:text-[5vw] text-center pb-5 my-16">
              <i>
                ** Problem Statements will be released on 01st March, 2025 at
                11:00 AM **
              </i>
            </h1>

            <div className="w-full mx-auto flex lg:flex-row md:flex-col s:flex-col xl:my-0 s:my-8 justify-evenly items-center">
              <div>
                <div className="flex flex-col items-center justify-center lg:w-[17vw] md:w-[30vw] aspect-square border shadow-lg rounded-full overflow-hidden hover:scale-110 transition duration-500 cursor-pointer">
                  <img
                    className="w-fit px-12"
                    src={require("../../assets/track_food.jpg")}
                    alt="GFG Logo"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <h1 className="text-2xl mt-4 text-center font-bold mb-6 xl:text-[1.8vw] s:text-[5vw]">
                  Healthcare
                </h1>
              </div>

              <div className="">
                <div className="flex flex-col items-center justify-center lg:w-[17vw] md:w-[30vw] aspect-square border shadow-lg rounded-full overflow-hidden hover:scale-110 transition duration-500 cursor-pointer">
                  <img
                    className="w-fit px-12"
                    src={require("../../assets/track_education.jpg")}
                    alt="GFG Logo"
                    style={{ objectFit: "fill" }}
                  />
                </div>
                <h1 className="text-2xl mt-4 text-center font-bold mb-6 xl:text-[1.8vw] s:text-[5vw]">
                  Ed-Tech
                </h1>
              </div>

              <div>
                <div className="flex flex-col items-center justify-center lg:w-[17vw] md:w-[30vw] aspect-square border shadow-lg rounded-full overflow-hidden hover:scale-110 transition duration-500 cursor-pointer">
                  <img
                    className="w-fit px-12"
                    src={require("../../assets/track_awareness.jpg")}
                    alt="GFG Logo"
                    style={{ objectFit: "fill" }}
                  />
                </div>
                <h1 className="text-2xl mt-4 text-center font-bold mb-6 xl:text-[1.8vw] s:text-[5vw]">
                  Miscellaneous
                </h1>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex flex-row justify-between items-center mr-6">
              <h1 className="text-4xl font-bold xl:my-6 s:mb-6">Hackathon Tracks</h1>
              <button onClick={downloadPS} className="xl:w-fit bg-gfg-green rounded-xl text-center cursor-pointer px-16 py-4 bold font-bold text-white s:w-full">Download PS</button>
            </div>

            <div className="w-full mx-auto">
              <div>
{/*                 <div className="xl:p-4 s:p-0 h-fit xl:w-full s:w-full s:mx-0">
                  {tracks.map((statements, index) => (
                    <PSAccordion
                      key={index}
                      name={statements.name}
                      statements={statements.problemStatements}
                      isOpen={openIndex === index}
                      accordionIndex={index}
                      toggleAccordion={() => toggleAccordion(index)}
                    />
                  ))}
                </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProblemStatementsPage;
