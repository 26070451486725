import React, { useState} from "react";
import "./hackathonHeader.css";
import { useNavigate } from "react-router-dom";
import { ISLOGGED, HACKTEAMTOKEN, PAYLOAD } from "../../utils/constants";

const HackathonHeader = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showNavigation, setShowNavigations] = useState(true);
  
  const navigate = useNavigate();

  // useEffect(() => {
  //   // Function to handle the completion of the dropdown animation
  //   const handleDropdownComplete = () => {
  //     // Get the checkbox element
  //     const toggleCheckbox = document.getElementById("toggle");

  //     // Toggle the checkbox based on the drawer state
  //     toggleCheckbox.checked = isDrawerOpen;
  //   };

  //   // Add an event listener to the dropdown element to listen for the end of the animation
  //   const dropdownDrawer = document.querySelector(".dropdown-drawer");
  //   dropdownDrawer.addEventListener("animationend", handleDropdownComplete);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     dropdownDrawer.removeEventListener(
  //       "animationend",
  //       handleDropdownComplete
  //     );
  //   };
  // }, [isDrawerOpen]);

  const handleHamburgerClick = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleLogout  = () => {
    localStorage.removeItem(ISLOGGED);
    localStorage.removeItem(HACKTEAMTOKEN);
    localStorage.removeItem(PAYLOAD);
    navigate("/login");
    window.location.reload();
  };

  return (
    <nav className="navbar">
      <div className="nav-left cursor-pointer" onClick={ () => navigate("/hackmatrix") }>
        <img
          className="w-20 aspect-square rounded-full ml-4 mr-2"
          src={require("../../assets/hm.png")}
          alt="GFG Logo"
        />
        <div className="nav-logo-txt text-3xl xs:hidden lg:block">
          <h2>Hack Matrix 3.0</h2>
        </div>
      </div>


      {/* <input type="checkbox" id="toggle" className="toggle-checkbox" />
      <label
        className="toggle-label"
        htmlFor="toggle"
        onClick={handleHamburgerClick}>
        <span className="toggle-icon"></span>
      </label> */}

      <div
        className={ "flex flex-row items-center gap-x-2 " + (showNavigation ? "flex" : "hidden") }>

        <button className= { "signin-button mr-4 " + ( localStorage.getItem(ISLOGGED) ? "block" : "hidden" )}  onClick={handleLogout}>Logout</button>
      </div>
    </nav>
  );
};

export default HackathonHeader;
