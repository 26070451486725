import React from "react";
import { useRef, useState, useEffect } from "react";
import Carousel from "react-elastic-carousel";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import FadeInSection from '../../utils/fade_in_section'
import PastEventCard from "../../Components/past_event_card/PastEventCard";
import UpcomingEventCard from '../../Components/upcoming_event_card/upcoming_event_card';

import axios from "axios";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // for smoothly scrolling
  });
};

const breakPoints = [
  { width: 1, itemsToShow: 1 }
];

const EventPage = () => {
  const carouselRef = useRef(null);
  const [events, setEvents] = useState([]);    
  const baseURL = "https://gfgweb-backend.vercel.app/api/event/all";
  let resetTimeout;

  useEffect( () => {
    axios.get(baseURL).then((response) => {
      setEvents( response.data['result'] );
    });
  }, []);

  //// console.log( events[0] );

  return (
    <>
      <Header/>
      <FadeInSection>
        <div className="flex mt-24 justify-around xs:flex-col lg:flex-row xs:items-center lg:items-start min-h-[75vh]">
          <img
            src={require("../../assets/Winners.gif")}
            className="xs:w-5/6 lg:w-1/3"
            alt="Event"
          />
          <div className="xs:w-5/6 lg:w-1/3 flex flex-col items-around gap-y-6 mt-4">
            <div className="font-[700] leading-tight xs:text-center lg:text-right xs:text-4xl lg:text-6xl">
              Journey into Wonder: Discover the Enchanting Events of the Season!
            </div>
            <div className="xs:text-center lg:text-right text-base">
              Experience unforgettable moments at our extraordinary events. From
              immersive workshops to captivating performances, our carefully
              curated lineup will ignite your senses and leave you inspired. Join
              us and create lasting memories. Explore our calendar and reserve
              your spot today.
            </div>
          </div>
        </div>

        {/* Upcoming Events */}
        {/* <div className="mt-24 mb-10 text-5xl font-bold mx-4">
          Upcoming Events
        </div>
        
        <div className="w-full">
            <Carousel 
              ref={carouselRef}
              easing="cubic-bezier(1,.15,.55,1.54)"
              tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
              transitionMs={1000}
              pagination={false}
              enableAutoPlay={true}
              showArrows={false}
              autoPlaySpeed={4000}
              isRTL={false}
              onNextEnd={({ index }) => {
                  clearTimeout(resetTimeout);
                  resetTimeout = setTimeout(() => {
                      carouselRef?.current?.goTo(0);
                  }, 4000); // same time
              }}
              breakPoints={breakPoints}
              disableArrowsOnEnd={true} 
              enableMouseSwipe={true} 
              outerSpacing={0} 
              loop={true}>

              <div className="flex justify-center align-center h-full">
                <UpcomingEventCard/>
              </div>
              <div className="flex justify-center align-center h-full">
                <UpcomingEventCard/>
              </div>
            </Carousel>
          
        </div> */}

        <div className="w-[90%] mx-auto">
          {/* Past Events */}
          <div className="mx-auto xs:mt-12 lg:mt-24 xs:mb-4 lg:mb-10 text-5xl font-bold">Events</div>

          {/* Event Cards here */}
          <div className="flex flex-col gap-y-4">
            {events.map((event) => <PastEventCard props={event} /> ) }
          </div>
        </div>

        {/* Back to top button */}
        <div className="flex flex-row justify-center mt-12">
          <button
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-full shadow"
            onClick={scrollToTop}>
            Back to Top
          </button>
        </div>
      </FadeInSection>
      <Footer/>
    </>
  );
}

export default EventPage;
