import React from "react";
import HackathonHeader from "../hacakthonHeader";
import Footer from "../Footer";
import ContactSupportCard from "../contactSupportCard";

const ContactSupportPage = () => {
  const deatils = [
    {
      name: "Pratham Mali",
      email: "pratham.mali22@pccoepune.org",
      contact: "+91 7058025957",
    },
    {
      name: "Sagar Karatagi",
      email: "sagar.karatagi22@pccoepune.org",
      contact: "+91 9307503539",
    },
    {
      name: "Harshal Mali",
      email: "harshal.mali23@pccoepune.org",
      contact: "+91 9322193906",
    },
    {
      name: "Anushka Patil",
      email: "anushka.patil23@pccoepune.org",
      contact: "+91 9322477753",
    },
    {
      name: "Rutuj Dhongade",
      email: "rutuj.dhongade22@pccoepune.org",
      contact: "+91 8625983460",
    },
    {
      name: "Siddesh Pohare",
      email: "siddesh.pohare22@pccoepune.org",
      contact: "+91 9322241926",
    },
  ];

  return (
    <>
      <div className="contact-us-container flex flex-col w-full justify-center items-center border border-slate-200 rounded-xl">
        <flex className="flex flex-row justify-center flex-wrap gap-x-8 my-12 gap-4">
          {deatils.map((member) => {
            return <ContactSupportCard props={member} />;
          })}
        </flex>
      </div>
    </>
  );
};

export default ContactSupportPage;
