import React from "react";
import Accordion from "./MyAccordion";
import { useState } from "react";

function FAQ(){
  const faqs = [
    {
      "title" : "How will Round 1 be conducted ?",
      "details" : "Round 1 will be taken in online format on 1st March.",
    },
    {
      "title" : "How will Round 2 be conducted ?",
      "details" : "Round 2 will be offline on 7 and 8th March.",
    },
    {
      "title" : "Will there be food?",
      "details" :  "Yes, food will be provided throughout the event hours."
    },
    {
      "title" : "What are the costs of participating?",
      "details" : "The registration fee for the hackathon is 300 rupees for each team, which is strictly non-refundable.",
    },
    {
      "title" : "Who can participate?",
      "details" : "Teams comprising 3-4 engineering students from any college, pursuing any stream, are eligible to participate in the hackathon.",
    },
    {
      "title" : "How can I get involved?",
      "details" : "Register for the hackathon along with your team by visiting our website https://gfgpccoe.in",
    },
    {
      "title" : "What is in for me?",
      "details" : "Learning new skills, looks good on your CV, networking, winning cash prizes, working as a team, meet great people, have great food and having fun!",
    },
    {
      "title" : "what should be team size?",
      "details" : "Teams size can be a minimum of 3 members and a maximum of 4 members",
    },
    {
      "title" : "Who will be judging?",
      "details" : "An Experienced Industry expert will be judging the individual's project",
    },
    {
      "title" : "Is there reimbursement for travel?",
      "details" : "The traveling costs are non-reimbursable; teams are responsible for covering their own expenses.",
    },
    {
      "title" : "Will there be wireless internet access?",
      "details" : "Yes, We will provide you with wireless internet access during offline round i.e Round II",
    },
    {
      "title" : "What should I bring?",
      "details" : "Each team member must bring their own laptop with required accessories. It is mandatory for all team members to carry their respective college ID cards.",
    },
    {
      "title" : "Where will the Hackathon take place?",
      "details" : "The hackathon will take place offline at Pimpri Chinchwad College of Engineering,Nigdi and it is necessary for all team members to be present.",
    },
    {
      "title" : "Can I also join remotely?",
      "details" : "No, we want this hackathon to be an onsite event so remotely joining as well as working with a remote team is not allowed.",
    },
    {
      "title" : "Do all team members need to be present in person?",
      "details" : "Indeed, all team members must be present in person for the Offline Round, i.e., Round II.",
    },
    {
      "title" : "Can I only participate in one challenge?",
      "details" : "During the event, participants are free to engage in other ongoing campus activities, except during vital times such as presentations,etc",
    },
    {
      "title" : "Who is keeping the intellectual property of my solution?",
      "details" : "The team that develops the project is the owner of the project. We do encourage making the code open source or sharing of code with others in the community, but it is not required to participate in the event. You are allowed to use open source libraries, your own libraries etc. to develop your solution!",
    },
  ]

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="p-4 h-fit xl:w-full s:w-[90%] s:mx-auto">
          {faqs.map((question, index) => (
            <Accordion
              key={index}
              title={question.title}
              details={question.details}
              isOpen={openIndex === index}
              toggleAccordion={() => toggleAccordion(index)}
            />
          ))}
      </div>
  );
};

export default FAQ;
