import React from "react";

const pratham = 'https://res.cloudinary.com/dgfxygdz7/image/upload/v1734611487/PRATHAM-modified_yhpkzz.webp'
const sagar = 'https://res.cloudinary.com/dgfxygdz7/image/upload/v1734611487/SAGAR-modified_efxooo.webp'
const harshal = 'https://res.cloudinary.com/dgfxygdz7/image/upload/v1734611485/HARSHAL-modified_a5hanl.webp'
const rutuj = 'https://res.cloudinary.com/dgfxygdz7/image/upload/v1734611487/RUTUJ-modified_i72jc6.webp'
const anushka = 'https://res.cloudinary.com/dgfxygdz7/image/upload/v1734611483/ANUSHKA-modified_akvqql.webp'
const siddesh = 'https://res.cloudinary.com/dgfxygdz7/image/upload/v1734611488/SIDDHESH-modified_qx6gkl.webp'



function TeamMembers() {
  return (
    <>
      <div className="flex flex-wrap justify-center xl:w-[70%] s:w-full xl:mx-auto">
        <div className="bg-slate-200 w-60 text-center rounded-md m-3 hover:scale-115 transition duration-500 ease-in-out hover:translate-y-[-10px] cursor-pointer">
          <div className="h-[240px]">
            <img
              src={pratham}
              alt=""
              className="object-cover object-top w-50 h-42"
            />
          </div>
          <div className="text-xl font-bold">Pratham Mali</div>
          <div>President</div>
          <div className="mt-2 mb-4 underline">+91 7058025957</div>
        </div>
        <div className="bg-slate-200 w-60 text-center rounded-md m-3 hover:scale-115 transition duration-500 ease-in-out hover:translate-y-[-10px] cursor-pointer">
          <div className="h-[240px]">
            <img
              src={sagar}
              alt=""
              className="object-cover object-top w-50 h-42"
            />
          </div>
          <div className="text-xl font-bold">Sagar Karatgi</div>
          <div>Vice-President</div>
          <div className="mt-2 mb-4 underline">+91 9307503539</div>
        </div>
        <div className="bg-slate-200 w-60 text-center rounded-md m-3 hover:scale-115 transition duration-500 ease-in-out hover:translate-y-[-10px] cursor-pointer">
          <div className="h-[240px]">
            <img
              src={harshal}
              alt=""
              className="object-cover object-top w-50 h-42"
            />
          </div>
          <div className="text-xl font-bold">Harshal Mali</div>
          <div>Technical Head</div>
          <div className="mt-2 mb-4 underline">+91 9322193906</div>
        </div>
        <div className="bg-slate-200 w-60 text-center rounded-md m-3 hover:scale-115 transition duration-500 ease-in-out hover:translate-y-[-10px] cursor-pointer">
          <div className="h-[240px]">
            <img
              src={anushka}
              alt=""
              className="object-cover object-top w-50 h-42"
            />
          </div>
          <div className="text-xl font-bold">Anushka Patil</div>
          <div>Technical Head</div>
          <div className="mt-2 mb-4 underline">+91 9322477753</div>
        </div>
        <div className="bg-slate-200 w-60 text-center rounded-md m-3 hover:scale-115 transition duration-500 ease-in-out hover:translate-y-[-10px] cursor-pointer">
          <div className="h-[240px]">
            <img
              src={rutuj}
              alt=""
              className="object-cover object-top w-50 h-42"
            />
          </div>
          <div className="text-xl font-bold">Rutuj Dhongade</div>
          <div>Technical Head</div>
          <div className="mt-2 mb-4 underline">+91 8625983460</div>
        </div>
        <div className="bg-slate-200 w-60 text-center rounded-md m-3 hover:scale-115 transition duration-500 ease-in-out hover:translate-y-[-10px] cursor-pointer">
          <div className="h-[240px]">
            <img
              src={siddesh}
              alt=""
              className="object-cover  object-top w-50 h-42"
            />
          </div>
          <div className="text-xl font-bold">Siddesh Pohare</div>
          <div>Marketing Head</div>
          <div className="mt-2 mb-4 underline">+91 9322241926</div>
        </div>
      </div>
    </>
  );
}

export default TeamMembers;
