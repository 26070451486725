import React from "react";
import { useState, useEffect } from "react";
import useConfetti from "../Confetti/useConfetti";
import HackathonHeader from "../hacakthonHeader";
import HackMatrixFooter from "../HackMatrixFooter/HackMatrixFooter";

const Finalists = () => {
  const [bestTime, setBestTime] = useState(false);
  const [bestDiceRoll, setBestDiceRoll] = useState(false);
  const { confetti, newBestTime, newBestDiceRoll, newBestGame } = useConfetti({
    bestTime,
    setBestTime,
    bestDiceRoll,
    setBestDiceRoll,
  });

  useEffect(() => {
    confetti();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
  };

  const teams = [
    {
      "idx": 0,
      "teamid": "HM0069",
      "teamName": "AlgoCrew",
      "college": "Pimpri Chinchwad College of Engineering",
      "track": "EdTech",
      "status": "SELECTED"
    },
   
   
   
    
    {
      "idx": 1,
      "teamid": "HM0068",
      "teamName": "Team Cipher",
      "college": "VIT",
      "track": "Healthcare",
      "status": "SELECTED"
    },
   
    {
      "idx": 2,
      "teamid": "HM0046",
      "teamName": "NEVER_SELECTED",
      "college": "VIT",
      "track": "Miscellaneous",
      
    },
    
  ];
  



  return (
    <>
      <HackathonHeader className="pb-2" />
      <div class="flex flex-col w-[90%] mx-auto xl:p-12 s:p-2 shadow-xl rounded-xl border border-slate-200 mt-[4%] items-center">
        <h1 className="font-bold xl:text-[2.5vw] s:text-[9vw] text-center xl:mb-12 xl:mt-0 s:mt-8 s:mb-8">
          {" "}
          Hack Matrix Winners
        </h1>

        <div class="w-[100%] py-2 flex flex-col items-center inline-block sm:px-6 lg:px-8">
          <div class="w-[100%] my-auto overflow-x-scroll">
            <table class="w-[100%] rounded-xl mx-auto">
              <thead class="bg-white border-b">
                <tr className="border border-slate-400">
                  <th
                    scope="col"
                    class="text-xl text-center font-bold text-gray-900 px-6 py-4 text-left">
                    Track
                  </th>
                  <th
                    scope="col"
                    class="text-xl text-center font-bold text-gray-900 px-6 py-4 text-left">
                    Team ID
                  </th>
                  <th
                    scope="col"
                    class="text-xl text-center font-bold text-gray-900 px-6 py-4 text-left">
                    Team Name
                  </th>
                 
                 
                </tr>
              </thead>
              <tbody >
                {teams.map((team) => {
                  return (
                    <tr class="border border-slate-400 cursor-pointer hover:scale-[1.03] transition duration-500">
                      
                        <td
                          rowSpan={1}
                          class="px-6 py-4 text-center font-bold whitespace-nowrap text-xl text-gray-900">
                          {team.track}
                        </td>
                     
                      <td class="text-xl text-gray-900 font-light px-6 py-4 text-center whitespace-nowrap">
                        {team.teamid}
                      </td>
                      <td class="text-xl text-gray-900 font-light px-6 py-4 text-center whitespace-nowrap">
                        {team.teamName}
                      </td>
                    
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <h2 className="text-xl my-8">
            Note: Teams that are in <strong> "STANDBY" </strong> can be selected
            as Finalists if any of the shortlisted teams from respective tracks
            are unable to participate for Round-2. In that case, organizing team will reach to you!
          </h2> */}
        </div>
      </div>

      {/* Back to top button */}
      <div className="block w-full flex flex-col justify-center items-center">
        <div className="xs:mt-12 w-fit">
          <button
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-full shadow xs:text-sm sm:text-lg"
            onClick={scrollToTop}>
            Back to Top
          </button>
        </div>
      </div>

      <HackMatrixFooter />
    </>
  );
};

export default Finalists;
