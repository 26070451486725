export const PS =  [
    {
      "track": "Ed-Tech",
      "title": "Create an interactive T&P system with personalized dashboards for admins and students. Admins post keyword-tagged opportunities, while students upload resumes, receiving notifications for relevant job offers based on their profiles.",
      "description": "Dashboard for Admin and students: Admin and student can view and manage personalized data and updates in an interactive dashboard. Student Profile with resume: Students create detailed profiles and upload resumes for job applications and opportunities. Posting by admin with keywords: Admin posts opportunities or notices with specific keywords for better searching. Notification for Latest and eligible opportunities: Students are notified about new and relevant opportunities based on their profile.",
      "psid": "ET01",
      "__v": 0
    },
    {
      
      "track": "Ed-Tech",
      "title": "Develop a student platform to track progress, lessons, and quizzes. Students engage with interactive content and quizzes, while global matchmaking pairs them with peers for concept-based quiz competitions, offering gamified rewards and credits for motivation.",
      "description": "A Student platform: A platform for students to track their w, lessons, and quizzes. Learning lessons and quizzes: Interactive lessons and quizzes for students to assess their knowledge. Global quiz matchmaking based on the concepts learned (gamified credits): Matches students with global peers for quizzes based on the topics they’ve studied, with gamified rewards.",
      "psid": "ET02",
      "__v": 0
    },
    {
     
      "track": "Ed-Tech",
      "title": "Create a YouTube-like educational platform for uploading, sharing, and viewing videos, with manual admin verification for content relevance. The platform includes a journey analyzer that visually tracks user progress, offering insights into their learning path.",
      "description": "YouTube-like platform: A platform for uploading, sharing, and viewing educational videos. Should allow only educational videos and content to be uploaded (manual checking by admin): Only approved educational content can be uploaded, ensuring quality and relevance. Journey analyser from user history (graphical representation of the history): A visual representation of the user's progress based on their viewed content.",
      "psid": "ET03",
      "__v": 0
    },
    {
    
      "track": "Ed-Tech",
      "title": "Design an integrated ERP system with role-based smart dashboards to streamline resource management, finances, and scheduling for educational institutions. It enhances communication, enables seamless resource sharing, and automates quiz result distribution, improving student engagement and feedback efficiency.",
      "description": "ERP System: A system that manages educational resources, finances, and schedules for the institution. Smart Dashboard (role-based dashboard analytics): Role-based dashboards with insights, alerts, and email notifications for educators. Resource sharing: Facilitates sharing of educational materials like notes and quizzes among educators and students. Quizzes result to be shared with all the students immediately: Quizzes results are automatically shared with students’ post-submission.",
      "psid": "ET04",
      "__v": 0
    },
    {
    
      "track": "Healthcare",
      "title": "Design an integrated healthcare management system with role-based dashboards for patients, doctors, and administrators to streamline workflows and improve communication. It includes a smart queuing system for appointments, inventory management, and automated medicine reminders to enhance patient care and optimize hospital operations.",
      "description": "Patient, Doctor, and Admin Dashboard: Dashboards for patients, doctors, and administrators to manage their tasks and monitor activities. Doctor and patient appointments: A smart queuing system to schedule and manage doctor-patient appointments efficiently. Inventory management: Manages hospital inventory, ensuring stock levels of medical supplies are maintained. Medicine reminders: Automated reminders for patients to take prescribed medicines.",
      "psid": "FH01",
      "__v": 0
    },
    {
    
      "track": "Healthcare",
      "title": "Design a secure mental health support platform with personalized user profiles to track history and progress, ensuring tailored assistance. Implement safeguards against fake profiles and enable secure communication with certified professionals to foster a trustworthy and supportive environment.",
      "description": "Platform for Mentally disturbed people: A platform designed to support individuals with mental health challenges. Profiles maintained for every person: Each user has a unique profile that tracks their history and progress. Precautions for fake profiles: Measures to prevent the creation of fake or fraudulent profiles on the platform. Connecting patients with other people: Facilitates direct communication between patients and certified mental health professionals.",
      "psid": "FH02",
      "__v": 0
    },
    {
      
      "track": "Healthcare",
      "title": "Design a supportive platform for expectant mothers, offering health and wellness information, a calendar for tracking appointments and progress, prenatal and postnatal care guidelines, and food/diet tracking to promote a healthy pregnancy journey.",
      "description": "Platform for Pregnant women: A platform providing health and wellness information for expectant mothers. Calendar keeping track of all details: A calendar tool for tracking doctor’s appointments, health progress, etc. Prenatal and postnatal care guidelines: Information and advice for prenatal and postnatal care. Food and diet plans tracking: A feature to track food and diet plans during pregnancy.",
      "psid": "FH03",
      "__v": 0
    },
    {
    
      "track": "Healthcare",
      "title": "Develop a mobile app for athletes to track health and fitness goals, featuring automatic calorie calculation, GPS-enabled running tracker, and a gamified goal-setting system to motivate users, track progress, and promote a healthy lifestyle.",
      "description": "Create mobile app: A mobile application designed for Sport players to track their health and fitness goals. Calories calculations based on exercise: Automatically calculates calories burned during physical activities. Running tracker using GPS: Tracks running sessions using GPS for distance and speed monitoring. Set personal goals and goal tracker (Gamified): Allows athletes to set fitness goals with a gamified tracking system.",
      "psid": "FH04",
      "__v": 0
    },
    {
     
      "track": "Miscellaneous",
      "title": "Design a financial management app that centralizes accounts, transactions, and investments for a comprehensive overview. Include a transaction analyzer, categorized spending, and data visualization to help users track and optimize their finances effectively.",
      "description": "Financial App development: Developing a financial app for personal finance management. Combining all accounts, transactions, and investments: Centralizes financial data from various accounts and investments into one platform. Transaction analyzer: Analyzes transaction patterns to identify spending trends and areas of improvement. Categorization & Visualization: Categorizes transactions and visualizes data for easier understanding.",
      "psid": "M01",
      "__v": 0
    },
    {
      
      "track": "Miscellaneous",
      "title": "Create a user-friendly student marketplace for buying and selling educational resources, with secure product type restrictions and user registrations. Include enhanced search functionality to help students find specific textbooks, stationery, and materials quickly and affordably.",
      "description": "Platform for students to buy and sell products required for educational purposes: A marketplace for students to buy and sell items like textbooks and stationery. Restrictions of products, users (buyers and sellers): Limits on the types of products and users allowed on the platform. Improved searching: Enhanced search functionality to help students find specific products easily.",
      "psid": "M02",
      "__v": 0
    },
    {
     
      "track": "Miscellaneous",
      "title": "Design an interactive competition platform for hosting hackathons, DSA, CP, and ML contests. Allow organizers to post events, enable easy participant registration, and incorporate a gamified leveling system, rewards, leaderboards, and social sharing to enhance engagement and foster community.",
      "description": "Platform for various competitions (like hackathons) and contestants: A platform for posting and participating in coding competitions and hackathons. Posting and applying for hackathons, DSA contests, CP contests, ML competitions: A place for organizers to post events and for participants to apply. Gamified Level: Levels and rewards for participants based on achievements in competitions. Leaderboard and Social Sharing: A leaderboard system to rank participants, with options for social sharing.",
      "psid": "M03",
      "__v": 0
    },
    {
    
      "track": "Miscellaneous",
      "title": "Design an all-in-one event management platform with real-time communication tools (chat, discussion boards, file sharing), an integrated event timeline, calendar with notifications, role-based access, budget tracking, and analytics to streamline collaboration and ensure event success.",
      "description": "Platform for organising teams to organize events.  Collaboration (Communication) tools:  Real-time chat, discussion boards, and file sharing for seamless communication. Event Timeline and Calendar with Notification System: Integrated calendar to visualize event timelines, deadlines, and milestones. Reminders and notifications of deadlines and updates. Team Coordination: Role-based access for different teams (Logistics, Marketing, Design, etc.) to collaborate effectively. Budget Tracking: Manage budgets and expenses for different event components. Analytics and Reporting: Insights on task progress, team productivity, and event success metrics.",
      "psid": "M04",
      "__v": 0
    }
  ]
